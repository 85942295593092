<template>
  <div
    v-resize
    v-document-class-watcher
    class="timeline yb-view relative"
    @mouseleave="hoverQueryIndex = null"
    @resize="resize"
    @document-class-change="createChart"
  >
    <div
      v-if="nowX > 0"
      class="absolute top-0 bottom-0 bg-yb-gray-alt-dark dark:bg-white"
      :style="{
        width: '1.5px',
        left: nowX + 'px' }"
    />
    <div
      v-if="nowX > 0"
      class="absolute rounded-full bg-yb-gray-alt-dark dark:bg-white"
      :style="{
        width: '12px',
        height: '12px',
        top: '-6px',
        left: (nowX - 6) + 'px' }"
    />
    <div
      v-if="nowX > 0"
      class="absolute rounded-full bg-yb-gray-alt-dark dark:bg-white"
      :style="{
        width: '12px',
        height: '12px',
        bottom: '-6px',
        left: (nowX - 6) + 'px' }"
    />
    <div
      v-if="nowX > 0"
      class="absolute text-sm top-0 px-1 rounded text-white bg-yb-gray-alt-dark dark:bg-yb-gray-alt-darker"
      :style="{
        left: (nowX + 12) + 'px' }"
    >
      {{ nowText }}
    </div>

    <div class="yb-view-header yb-body-background">
      <div class="flex flex-row">
        <div class="flex-grow-0 font-light text-sm mb-4 px-1 rounded text-white bg-yb-gray-alt-dark dark:bg-yb-gray-alt-darker">
          {{ zoomDisplayRange }}
        </div>
      </div>
      <div class="flex flex-row flex-nowrap w-full relative">
        <div class="w-40" />
        <div class="relative w-full" :style="{ height: (1 * rowHeight) + 'px' }">
          <svg class="top-0 bottom-0 left-0 right-0 absolute w-full">
            <g ref="axis" />
          </svg>
        </div>
      </div>
    </div>

    <div class="yb-view-content-scroll-y">
      <div
        class="flex flex-row flex-nowrap w-full select-none overflow-hidden"
        :style="{
          height: (rowHeight * (1 + rows)) + 'px',
        }"
      >
        <div ref="pools" class="w-40" @click="onClick">
          <div v-for="(pool, index) in pools" :key="index" class="cluster-wrapper">
            <div class="p-2 w-full flex flex-row flex-nowrap items-center justify-between font-semibold text-xs whitespace-nowrap" :style="{ height: rowHeight + 'px' }">
              <div v-if="pool.cluster_name && !pool.name" style="max-width: calc(100% - 1rem)" class="flex flex-row flex-nowrap items-center relative">
                <div class="flex flex-row flex-nowrap items-center px-1 rounded text-white bg-yb-gray-alt-dark dark:bg-yb-gray-alt-darker w-full">
                  <yb-icon icon="hierarchy" class="yb-button-icon-md flex-shrink-0" />
                  <div class="truncate text-sm font-light">
                    {{ pool.cluster_name }}
                  </div>
                </div>
                <div v-tooltip="'Exclude this cluster (' + pool.cluster_name + ') from timeline'" class="flex items-center absolute -right-5" @click="$emit('exclude', pool.cluster_name)">
                  <yb-icon icon="delete-circle" class="ml-1 yb-button-icon invisible remove-icon cursor-pointer" />
                </div>
                <div class="w-4" />
              </div>
              <template v-else>
                <div class="w-4" />
                <div>{{ pool.name }}</div>
              </template>
            </div>
            <div v-for="row in pool.max_concurrency" :key="row" :style="{ height: rowHeight + 'px' }" class="p-2 font-light text-xs text-right whitespace-nowrap">
              [{{ row }}]
            </div>
          </div>
        </div>
        <div ref="container" class="relative w-full" style="margin-top: 48px" @click="click">
          <canvas ref="canvas" class="top-0 bottom-0 absolute" style="margin-top: -48px" :class="hoverQueryIndex ? 'cursor-pointer' : 'cursor-move'" @mousemove="hover" />
          <canvas ref="hitCanvas" class="top-0 bottom-0 absolute invisible" />
        </div>
      </div>
      <div v-if="debug" class="p-2 h-auto rounded-lg border border-black dark:border-yb-gray-medium">
        <pre class="w-full h-full text-xs select-text">{{ debugText }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'debounce'
import d3 from '@/lib/d3'
import { roundRect, setupCanvas } from '@/lib/canvas'
import { seriesColors } from '@/services/constants'
import { formatDateTime, formatDateTimeLong, formatDateTimeAbbrev, formatTime, ONE_HOUR, ONE_SECOND } from '@/lib/time'

const AUTO_UPDATE_MARGIN = 120
const ZOOM_TRANSITION_TIME = 1250
const ROW_HEIGHT = 24

/**
 * NB: about d3 zooming transforms...
 *
 * A d3 zoom transform has 3 values: (k, x, y).  For our purposes, we only care about k and x, the zoom and x
 * offset.  The best way to understand how to programmatically compute these is to read this article:
 *
 *  http://emptypipes.org/2016/07/03/d3-panning-and-zooming/
 *
 * Of note in this article (quote):
 *
 *   What if we want to zoom in so that they’re 10 pixels apart? We’ll first need to calculate the scale factor, k:
 *
 *       var k = 10 / (xScale(1020) - xScale(1010))  //~ 12.5
 *
 *   Let’s say we want the point 1010 to be positioned at pixel 200. We need to determine tx such that 200 = tx + k × xScale(1010)
 *
 *       var tx = 200 - k * xScale(1010) //-2600
 *
 * You will see this at work in this component when autoUpdate is set, and the updateToNow() method
 * is called externally or during a zoom interaction.  The goal is to display "now" in a fixed
 * position along the x axis.  To do this, the zoom handler computes where "now" should be using
 * a d3 scale, and overrides the zoom interaction x sent by the d3 event transform.  This lets the
 * zooming stay in a stable position when auto update is on.
 */

// Precompute highlight opacity sequence.
const highlightSequence = []
for (let i = 1; i <= 10; ++i) { highlightSequence.push(i) }
for (let i = 10; i >= 1; --i) { highlightSequence.push(i) }

export default {
  props: {
    pools: {
      type: Array,
      required: true
    },
    queries: {
      type: Array,
      required: true
    },
    queueIndicators: {
      type: Array,
      default: () => []
    },
    autoUpdate: {
      type: Boolean,
      required: false,
      default: false
    },
    autoUpdateMargin: {
      type: Number,
      required: false,
      default: AUTO_UPDATE_MARGIN
    },
    zoomRange: {
      type: Number,
      required: false
    },
    highlightQuery: {
      type: Number,
      required: false
    }
  },
  emits: ['click', 'exclude', 'hover', 'zoom', 'zoomstart', 'zoomend', 'auto-off'],
  data() {
    return {
      width: 0,
      height: 0,
      zooming: {},
      startDate: Number.MAX_VALUE,
      endDate: Number.MIN_VALUE,
      rowHeight: ROW_HEIGHT,
      rows: 0,
      hoverQueryIndex: null,
      debug: false,
      debugText: '',
      zoomDisplayRange: '',
      nowX: 0,
      nowText: '',
      drawn: 0
    }
  },
  computed: {
    autoUpdateOffsetFraction() {
      const { width, autoUpdateMargin } = this
      if (width === 0) {
        return 0.1
      }
      return autoUpdateMargin / width
    }
  },
  watch: {
    queries(_) {
      if (!_.length) {
        return
      }
      const { xScale } = this
      if (!xScale) {
        this.createChart()
      } else {
        this.createQueryIndex()
        this.draw()
      }
    },
    pools() {
      this.createChart()
    },
    autoUpdate() {
      this.draw()
    },
    hoverQueryIndex(_) {
      this.$emit('hover', _ > 0 ? this.activeQueries[_ - 1]?.query : null)
      if (_) {
        this.draw()
      }
    }
  },
  beforeMount() {
    this.createChart = debounce(this.createChartImpl.bind(this), 100)
  },
  mounted() {
    this.createChart()
  },
  methods: {
    createChartImpl() {
      const { chart, canvas, hitCanvas, container } = this.$refs
      if (!container) {
        return
      }

      // Calculate the min/max date range, index the pools and queries and such.
      this.createQueryIndex()

      // Capture dom elements and geometry.
      this.canvasMargin = { top: 0 * ROW_HEIGHT, right: 0, bottom: 0, left: 0 }
      const width = this.width = container.clientWidth
      const height = this.height = (3 + this.rows) * ROW_HEIGHT // +3 for top/bottom axis
      this.dark = document.documentElement.classList.contains('dark')

      // Install geometry to dom and canvas.
      d3.select(chart).attr('width', width);
      [canvas, hitCanvas].forEach(c => setupCanvas(c, width, height))

      // Setup initial scale scale.
      const endDate = +new Date()
      this.xScale = this.createScale(this.startDate, !this.highlightQuery ? endDate : this.endDate)

      // Setup zoom on canvas.
      const zoom = this.createZoom()

      // Set initial zoom/scale.
      if (this.zoomRange) {
        this.zoomAutoRange(this.zoomRange, 0)
      } else if (this.autoUpdate) {
        zoom.scaleTo(d3.select(canvas).transition().duration(1), 1)
      }

      // Do initial draw.
      this.draw()
    },

    createScale(startDate, endDate) {
      const { canvasMargin, width } = this

      return d3.scaleTime()
        .domain([startDate, endDate])
        .range([0, width - canvasMargin.left])
    },

    createZoom() {
      const { canvasMargin, xScale, width, height, zooming } = this
      const { canvas } = this.$refs

      // Figure out the max scale extent for 10s of width.
      const now = +new Date()
      const scaleExtent = (width / (xScale(now) - xScale(now - (10 * ONE_SECOND))) + 100)

      // Create the zoom.
      const zoom = this.zoom = d3.zoom()
        .filter((event) => {
          if (this.zoomActive()) {
            return false
          }
          const wheelEvent = event instanceof WheelEvent
          const wheelWithSecondaryKey = wheelEvent && (!!event.ctrlKey || !!event.metaKey || !!event.altKey)
          const nonWheel = !wheelEvent
          return (wheelWithSecondaryKey || nonWheel) && !event.button
        })
        .scaleExtent([0.1, scaleExtent])
        .extent([[0, 0], [width - canvasMargin.left - canvasMargin.right, height]])
      zoom.on('start', this.onZoomStart.bind(this))
      zoom.on('end', this.onZoomEnd.bind(this))
      zoom.on('zoom', (event) => {
        const { x, y, k } = event.transform

        // Position the domain such that we show x at fixed position for auto update during interactive zooming.
        if (this.autoUpdate && !zooming.pan) {
          const transform = d3.zoomIdentity
            .translate(0, 0)
            .scale(k)
          this.xScale = transform.rescaleX(xScale)
          this.updateToNow(true)
        } else {
          const transform = d3.zoomIdentity
            .translate(-canvasMargin.left, 0)
            .translate(x, y)
            .scale(k)
            .translate(canvasMargin.left, 0)
          this.xScale = transform.rescaleX(xScale)
          this.draw()
        }
        this.$emit('zoom', event)
      })
      d3.select(canvas).call(zoom)

      // Reset zoom states.
      Object.keys(zooming).forEach(zoom => delete zooming[zoom])

      return zoom
    },

    createQueryIndex() {
      const poolsMap = {}
      this.rows = 0
      this.max_concurrency = 0
      this.pools.forEach((pool) => {
        poolsMap[pool.pool_key] = {
          ...pool,
          rowIndex: this.rows + 1,
          slotIndex: this.max_concurrency
        }
        this.rows += pool.max_concurrency + 1
        this.max_concurrency += pool.max_concurrency
      })
      this.startDate = Number.MAX_SAFE_INTEGER
      this.endDate = Number.MIN_SAFE_INTEGER
      let highlightQuery
      this.activeQueries = this.queries
        .filter(query => !!query.execution_time)
        .map((query) => {
          this.startDate = Math.min(this.startDate, query.execution_time)
          this.endDate = Math.max(this.endDate, query.execution_time)
          const pool = poolsMap[query.pool_key]
          if (this.highlightQuery === query.query_id) {
            highlightQuery = query
          }
          return {
            query,
            pool,
            rowIndex: pool && (pool.rowIndex + query.slot),
            slotIndex: pool && (pool.slotIndex + query.slot)
          }
        })
        .filter(activeQuery => !!activeQuery.pool)
      if (highlightQuery) {
        const buffer_ms = parseInt(highlightQuery.run_ms / 4)
        this.startDate = +new Date(highlightQuery.execution_time)
        this.endDate = this.startDate + highlightQuery.run_ms
        this.startDate -= buffer_ms
        this.endDate += buffer_ms
      } else if (this.startDate === Number.MAX_SAFE_INTEGER || this.endDate === Number.MIN_SAFE_INTEGER) {
        this.endDate = +new Date()
        this.startDate = this.endDate - ONE_HOUR
      }
      this.poolsMap = poolsMap
    },

    drawAxis() {
      const { xScale, width, canvasMargin } = this

      const axisTop = d3.axisTop(xScale)
        .tickFormat(formatDateTimeAbbrev)
        .ticks(parseInt(width / 100))
        .tickPadding(8)

      d3.select(this.$refs.axis)
        .attr('transform', `translate(${canvasMargin.left},${this.rowHeight})`)
        .call(axisTop)
    },

    drawQueries(canvas, forHitTesting) {
      const { xScale, width, height, canvasMargin, rowHeight, hoverQueryIndex } = this
      const context = canvas.getContext('2d')
      context.save()
      context.clearRect(0, 0, width, height)

      // Draw each query.
      const range = xScale.range()
      const now = +new Date()
      this.activeQueries.forEach((activeQuery, index) => {
        const { query } = activeQuery
        const x1 = xScale(query.execution_time)
        const x2 = xScale(typeof query.run_ms === 'number' && !query.running ? query.execution_time + query.run_ms : now)
        if ((x1 < range[0] || x1 > range[1]) && (x2 < range[0] || x2 > range[1])) {
          return
        }
        context.beginPath()
        const queryIndex = (index + 1)
        if (!forHitTesting) {
          if (!!this.highlightQuery && this.highlightQuery === query.query_id) {
            const opacity = 1 / highlightSequence[this.drawn % highlightSequence.length]
            const bg = seriesColors[activeQuery.slotIndex % seriesColors.length].bg
            const r = parseInt(bg.substring(1, 3), 16)
            const g = parseInt(bg.substring(3, 5), 16)
            const b = parseInt(bg.substring(5, 7), 16)
            context.strokeStyle = `rgba(${r}, ${g}, ${b}, ${opacity})`
          } else {
            context.strokeStyle = seriesColors[activeQuery.slotIndex % seriesColors.length].bg
          }
        } else {
          // Encode query id as color for mouseover hittest lookup
          const r = (queryIndex & 0xFF0000) >> 16
          const g = (queryIndex & 0x00FF00) >> 8
          const b = (queryIndex & 0x0000FF)
          context.strokeStyle = `rgb(${r},${g},${b})`
        }
        context.lineWidth = queryIndex === hoverQueryIndex ? 12 : 8
        context.lineCap = 'round'
        const y = canvasMargin.top + (rowHeight * (activeQuery.rowIndex + 1)) - rowHeight / 2
        context.moveTo(canvasMargin.left + x1, y)
        context.lineTo(canvasMargin.left + (x2 - x1 < 1) ? x1 + 1 : x2, y)
        context.stroke()

        context.beginPath()
        context.fillStyle = context.strokeStyle
        context.arc(canvasMargin.left + x1, y, 5.5, 0, 2 * Math.PI, false)
        context.fill()
      })

      // Indicate whether hit test canvas is invalid.
      context.restore()
      this.hitTestInvalid = !forHitTesting
    },

    drawRows() {
      const { canvasMargin, width, rowHeight } = this
      const { canvas } = this.$refs
      const context = canvas.getContext('2d')

      context.beginPath()
      context.strokeStyle = this.dark ? '#666' : '#ddd'
      context.lineWidth = 1
      context.lineCap = 'square'
      for (let row = 0; row < this.rows; ++row) {
        context.moveTo(canvasMargin.left, canvasMargin.top + (rowHeight * (row + 1)))
        context.lineTo(width - canvasMargin.right, canvasMargin.top + (rowHeight * (row + 1)))
      }
      context.stroke()
    },

    drawNowIndicator() {
      const { canvasMargin, xScale } = this
      const { pools } = this.$refs

      const now = +new Date()
      this.nowX = pools.offsetWidth + canvasMargin.left + parseInt(xScale(now))
      this.nowText = formatTime(now)
    },

    drawQueuedIndicators() {
      const { canvasMargin, xScale, rowHeight } = this
      const { canvas } = this.$refs
      const transform = d3.zoomTransform(canvas)
      if (!transform) {
        return
      }

      // Draw queue indicators if we are zoomed in sufficiently.
      const drawQueuedIndicators = transform.k > 1
      const strideIndicator = Math.max(1, parseInt((30 - transform.k) / 10)) // at lower zoom levels, don't show as many indicators
      if (drawQueuedIndicators) {
        const context = canvas.getContext('2d')
        context.save()
        context.lineWidth = 1
        context.lineCap = 'square'
        context.font = '14px sans serif'
        context.textBaseline = 'middle'
        this.queueIndicators.forEach((qi, index) => {
          const pool = this.poolsMap[qi.pool_key]
          if (!pool) {
            // User can't see this pool, so we should ignore.
            return
          }
          if (strideIndicator > 1 && (index % strideIndicator) !== 0) {
            return
          }
          const x = xScale(qi.date)
          const queueText = String(qi.queued)
          const queueTextWidth = context.measureText(queueText).width
          context.fillStyle = '#888'
          roundRect(
            context,
            canvasMargin.left + x - (queueTextWidth / 2) - 6,
            canvasMargin.top + (rowHeight * (pool.rowIndex - 1)) + 3,
            queueTextWidth + 8,
            rowHeight - 6,
            null,
            true,
            false
          )
          context.fillStyle = '#FFF'
          context.fillText(queueText, canvasMargin.left + x - (queueTextWidth / 2) - 2, canvasMargin.top + (rowHeight * (pool.rowIndex)) - (rowHeight / 2) + 1)
        })
        context.restore()
      }
    },

    drawDebug() {
      // Draw zoom debug.
      if (this.debug) {
        const { xScale, width, zooming, zoomRange } = this
        const { canvas } = this.$refs

        const transform = d3.zoomTransform(canvas)
        const domain = xScale.domain()
        this.debugText = [
          `now: ${formatDateTimeLong(new Date())}, time range: ${+new Date() - +new Date(this.startDate)}ms`,
          `domain: ${formatDateTimeLong(domain[0])} - ${formatDateTimeLong(domain[1])}, ${+domain[1] - +domain[0]}ms`,
          `start/end: ${formatDateTimeLong(new Date(this.startDate))} - ${formatDateTimeLong(new Date(this.endDate))}, ${+new Date(this.endDate) - +new Date(this.startDate)}ms`,
          `width: ${width}`,
          `k: ${transform.k.toFixed(2)}, x: ${transform.x.toFixed(2)}`,
          `zoom active: ${this.zoomActive()}, zooming: ${Object.keys(zooming)}, range: ${zoomRange}`
        ].join('\n')
      }
    },

    draw() {
      const { canvas } = this.$refs
      if (!this.width || !canvas) {
        return
      }
      this.drawAxis()
      this.drawQueries(canvas, false)
      this.drawRows()
      this.drawNowIndicator()
      this.drawQueuedIndicators()
      this.drawDebug()
    },

    highlight() {
      this.drawn++
      this.draw()
    },

    hover($event) {
      // NB: what's going on here?
      //     we paint a hit test canvas, which is not visible, that encodes
      //     color of query uniquely by query index into rgb values, which we
      //     can then read back using x/y coordinate via context.getImageData() API.
      //     this rgb value is then the identifier of the query on hover.

      const { hitCanvas } = this.$refs
      if (!hitCanvas) {
        return // we are opening or closing, and this doesn't matter anyway
      }

      if (this.hitTestInvalid) {
        this.drawQueries(hitCanvas, true)
      }

      // Get pixel under cursor
      const x = $event.offsetX * window.devicePixelRatio
      const y = $event.offsetY * window.devicePixelRatio
      const pixel = hitCanvas.getContext('2d').getImageData(x, y, 1, 1).data

      // Convert unique pixel color to query id.
      const queryIndex = (pixel[0] << 16) + (pixel[1] << 8) + pixel[2]
      if (queryIndex > 0) {
        false && console.log('Got pixel of event (', x, y, ') index:', queryIndex)
        this.hoverQueryIndex = queryIndex
      } else {
        this.hoverQueryIndex = null
      }
    },

    click($event) {
      if (!!this.hoverQueryIndex && this.hoverQueryIndex > 0) {
        this.$emit('click', this.activeQueries[this.hoverQueryIndex - 1]?.query)
      }
    },

    updateToNow(force) {
      if (!force && (this.zoomActive() || this.hoverQueryIndex)) {
        false && console.log('Ignoring update to now')
        return
      }
      const { xScale, autoUpdateOffsetFraction } = this
      const { canvas } = this.$refs
      if (!xScale || !canvas) {
        return
      }

      // Get the time range of visible and shift domain.
      const domain = xScale.domain()
      const displayedTime = +domain[1] - +domain[0]
      const now = +new Date() + parseInt(displayedTime * autoUpdateOffsetFraction)

      // Shift x transform and redraw.
      this.xScale = xScale.domain([now - displayedTime, now])
      this.draw()

      // Store updated transform.
      const transform = d3.zoomTransform(canvas)
      const xOff = xScale(+new Date() + (displayedTime * autoUpdateOffsetFraction)) - xScale(+new Date())
      transform.x = xScale(this.startDate) + (xOff / 2)

      // Update the displayed time range.
      this.calculateZoomDisplayRange()
    },

    zoomActive() {
      return Object.keys(this.zooming).length > 0
    },

    zoomOut() {
      if (this.zoomActive()) { return }
      const { zoom, zooming } = this
      const { canvas } = this.$refs
      zooming.out = true
      zoom.scaleBy(
        d3.select(canvas)
          .transition()
          .on('end.out', () => delete zooming.out)
          .duration(ZOOM_TRANSITION_TIME),
        1 / 4)
    },

    zoomIn() {
      if (this.zoomActive()) { return }
      const { zoom, zooming } = this
      const { canvas } = this.$refs
      zooming.in = true
      zoom.scaleBy(
        d3.select(canvas)
          .transition()
          .on('end.in', () => delete zooming.in)
          .duration(ZOOM_TRANSITION_TIME),
        4)
    },

    zoomReset() {
      const { zoom, zooming } = this
      const { canvas } = this.$refs
      zooming.reset = true
      d3.select(canvas).transition()
        .on('end.reset', () => {
          // Delete all zoom keys.
          Object.keys(zooming).forEach(zoom => delete zooming[zoom])
        })
        .duration(ZOOM_TRANSITION_TIME)
        .call(
          zoom.transform,
          d3.zoomIdentity
        )
    },

    zoomTo(k) {
      if (this.zoomActive()) { return }
      const { zoom, zooming } = this
      const { canvas } = this.$refs
      zooming.to = true
      zoom.scaleTo(
        d3.select(canvas)
          .transition()
          .on('end.to', () => delete zooming.to)
          .duration(ZOOM_TRANSITION_TIME),
        k)
    },

    zoomAutoRange(displayedTime, duration) {
      if (this.zoomActive()) { return }
      const { zoom, zooming, width, autoUpdateOffsetFraction } = this
      const { canvas } = this.$refs

      // Calculate new k for target zoom transform.
      const now = +new Date()
      const nowWithGap = now + (displayedTime * autoUpdateOffsetFraction)
      const then = nowWithGap - displayedTime
      const xScale = this.createScale(this.startDate, now)
      const k = width / (xScale(nowWithGap) - xScale(then))

      // Do zoom.
      const transform = d3.zoomTransform(d3.select(canvas))
      false && console.log('start zoom: k', transform.k, 'to', k)
      zooming.auto = true
      zoom.scaleTo(
        d3.select(canvas)
          .transition()
          .on('end.auto', () => delete zooming.auto)
          .duration(duration ?? ZOOM_TRANSITION_TIME),
        k)
    },

    zoomScale() {
      const { canvas } = this.$refs
      return d3.zoomTransform(canvas).k
    },

    calculateZoomDisplayRange() {
      const { xScale } = this
      if (!xScale) {
        return ''
      }
      const domain = xScale.domain()
      this.zoomDisplayRange = `${formatDateTime(domain[0])} to ${formatDateTime(domain[1])}`
    },

    onClick(event) {
      this.debug = event.detail >= 3
    },

    onZoomStart(event) {
      if (event?.sourceEvent) {
        if (event.sourceEvent instanceof WheelEvent) {
          this.zooming.wheel = true
        } else {
          this.zooming.pan = true
        }
      }
      false && console.log('Zoom start')
      this.$emit('zoomstart', event)
    },

    onZoomEnd(event) {
      const zoomKeys = Object.keys(this.zooming)
      this.$nextTick(() => {
        this.calculateZoomDisplayRange()
        this.$emit('zoomend', { event, zooming: zoomKeys })
      })
      delete this.zooming.wheel
      delete this.zooming.pan
      false && console.log('Zoom end')
      if (this.autoUpdate) {
        // Determine if "now" is offscreen, and if so we must make an event to allow auto update to be switched off.
        const { xScale } = this
        const domain = xScale.domain()
        const now = new Date()
        const offscreen = now.getTime() < domain[0].getTime() || now.getTime() > domain[1].getTime()
        if (offscreen) {
          this.$emit('auto-off')
        }
      }
    },

    resize({ detail }) {
      // Did our width change while we are rendering?
      if (!!this.activeQueries && detail?.width !== this.width) {
        this.createChart()
      }
    },

    displayedTime() {
      const { xScale } = this
      const domain = xScale.domain()
      return +domain[1] - +domain[0]
    }
  }
}
</script>

<style scoped lang="postcss">
.cluster-wrapper:hover .remove-icon {
  @apply visible;
}

div.timeline :deep(.domain) {
  stroke-width: 1.5px;
}

div.timeline :deep(.tick) text {
  @apply font-medium;
}
</style>
