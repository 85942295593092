// Adapted from: observablehq DOM context2d: https://github.com/observablehq/stdlib/blob/main/src/dom/context2d.js
export function setupCanvas(canvas, width, height) {
  const dpi = window.devicePixelRatio
  const context = canvas.getContext('2d')
  canvas.width = width * dpi
  canvas.height = height * dpi
  canvas.style.width = `${width}px`
  context.scale(dpi, dpi)
}

// From: https://stackoverflow.com/questions/1255512/how-to-draw-a-rounded-rectangle-using-html-canvas
export function roundRect(context, x, y, width, height, radius, fill, stroke) {
  const cornerRadius = { upperLeft: 5, upperRight: 5, lowerLeft: 5, lowerRight: 5 }
  if (typeof stroke === 'undefined') {
    stroke = true
  }
  if (typeof radius === 'object') {
    for (const side in radius) {
      cornerRadius[side] = radius[side]
    }
  }

  context.beginPath()
  context.moveTo(x + cornerRadius.upperLeft, y)
  context.lineTo(x + width - cornerRadius.upperRight, y)
  context.quadraticCurveTo(x + width, y, x + width, y + cornerRadius.upperRight)
  context.lineTo(x + width, y + height - cornerRadius.lowerRight)
  context.quadraticCurveTo(x + width, y + height, x + width - cornerRadius.lowerRight, y + height)
  context.lineTo(x + cornerRadius.lowerLeft, y + height)
  context.quadraticCurveTo(x, y + height, x, y + height - cornerRadius.lowerLeft)
  context.lineTo(x, y + cornerRadius.upperLeft)
  context.quadraticCurveTo(x, y, x + cornerRadius.upperLeft, y)
  context.closePath()
  if (stroke) {
    context.stroke()
  }
  if (fill) {
    context.fill()
  }
}
